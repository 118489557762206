<template>
    <div>
        <el-header style="height:210px; padding:0;">
                <div style="height:40px;margin-bottom:24px;min-width:740px;">
                    <div class="divider"></div>
                    <div class="detail-header-left">
                        <span class="title">{{missionBasic.title}}
                            <el-tag size="mini" :type="global.STATES[missionBasic.statusCode].type" style="margin-bottom:5px">
                                {{global.STATES[missionBasic.statusCode].label}}
                            </el-tag>
                        </span>
                    </div>  
                    <div style="float:right">
                        <el-button icon="el-icon-edit" size="medium" style="width:98px;" 
                                  @click="visible=true" :disabled="!editAble">
                            编辑
                        </el-button>
                    </div>
                </div>
                <div style="height:100px;width:100%;min-width:740px;margin-bottom:50px;">
                    <div class="description">
                        <div>
                            <span>任务描述：</span>
                            <span style="color:#595959;">{{missionBasic.description}}</span>
                        </div>
                        <div v-show="missionBasic.taskUrl">
                            <span>任务链接：</span>
                            <el-link style="color:rgba(56,121,238,1);" :href="missionBasic.taskUrl" target='_blank'>{{missionBasic.taskUrl}}</el-link>
                        </div>
                        <div>
                            <span>任务封面：</span>
                            <el-image v-if="missionBasic.coverUrl" style="height:90px;vertical-align:-80px;margin-left:0" :src="missionBasic.coverUrl" fit="contain" :preview-src-list="[missionBasic.coverUrl]">
                            </el-image>
                            <span v-else>暂无封面</span>
                        </div>
                    </div>
                    <div class="detail-card">
                        <div class="firstline">
                            <div style="float:left;margin-left:16px;">
                                <span style="color:rgba(56, 121, 238, 1)">已完成</span>
                            </div>
                            <div style="float:right;margin-right:41px;"> 
                                <span style="color:rgba(111, 105, 105, 1)">未完成</span>
                            </div>
                        </div>
                        <div class="secondline">
                            <div style="float:left;margin:0 20px ;min-width:53px;">
                                <span style="color:rgba(56, 121, 238, 1)">{{commitCount}}</span>
                            </div>   
                            <div style="float:left;">
                                <span style="color:rgba(56, 121, 238, 1)">/</span>
                            </div>  
                            <div style="float:left;margin:0 0 0 20px;">
                                <span style="color:rgba(111, 105, 105, 1)">{{unCommitCount}}</span>
                            </div>       
                        </div>
                    </div>
                </div>
        </el-header>
        <div style="height:40px;">
            <div class="divider"></div>
            <div style="float:left;font-size:18px;">
                <span>已完成任务列表</span>     
            </div>
        </div>
        <div>
            <div class="secondHeader">
                <div class="inputGroup">
                    <el-input placeholder="输入评论员姓名搜索"
                                v-model="searchData.keyWord"
                                prefix-icon="el-icon-search">
                    </el-input>
                    <el-cascader placeholder="请选择队伍"
                                v-model="searchData.departmentId"
                                :options="selectDepartmentOptions"
                                :props="{expandTrigger:'hover'}"
                                :disabled="!editAble"
                                clearable>
                    </el-cascader>
                    <el-button type="text" @click="search(false)">搜索</el-button>
                </div>
            </div>
            <div>
                <el-table :data="missionDetail">  
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-form label-position="center" class="table-expand">
                                <el-form-item label="内容">
                                    <span style="white-space:pre-wrap">{{props.row.content}}</span>
                                </el-form-item>
                                <el-form-item label="截图">
                                    <el-image v-for="pic in props.row.picList" :key="pic"
                                                style="width:100px;height:100px;margin-right:10px"
                                              :src="pic" :preview-src-list="props.row.picList">
                                    </el-image>
                                </el-form-item>
                            </el-form>
                        </template>
                    </el-table-column>   
                    <el-table-column prop="userName" label="评论员"></el-table-column>
                    <el-table-column prop="gmtCreated" label="提交时间"></el-table-column>
                    <el-table-column label="提交状态">
                        <template slot-scope="scope">
                            <el-tag :type="global.CHECK_STATES[scope.row.status].type">{{global.CHECK_STATES[scope.row.status].label}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-link type="primary" :underline="false" @click="deleteMissionDetail(scope.row.taskResultId)">删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev,pager,next"
                            :page-size.sync="global.PAGE_SIZE"
                            @current-change="handleCurrentChange"
                            :current-page.sync="currentPage"
                            :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="visible" title="编辑任务信息" class="container">
            <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                 <el-form-item label="任务标题：" prop="title">
                     <el-input v-model="form.title"></el-input>
                 </el-form-item>
                 <el-form-item label="任务描述：" prop="description">
                     <el-input type="textarea"
                                :row="3"
                                placeholder="请输入任务描述"
                                v-model="form.description"
                                style="width:75%">
                     </el-input>
                 </el-form-item>
                 <el-form-item label="(可选)任务链接：">
                     <el-input v-model="form.taskUrl"></el-input>
                 </el-form-item>
                 <el-form-item label="负责队伍：" prop="targetDepartmentIdList">
                     <el-cascader placeholder="负责队伍"
                                  v-model="form.targetDepartmentIdList"
                                  :options="departments"
                                  :props="{multiple:true}"
                                  collapse-tags
                                  clearable>
                     </el-cascader>
                 </el-form-item>
                 <el-form-item label="起止时间：" prop="datetimeRange">
                    <el-date-picker v-model="form.datetimeRange"
                        type="datetimerange"
                        range-separator="-"
                        start-placeholder="选择起始时间"
                        end-placeholder="选择终止时间"
                        value-format="yyyy-MM-dd hh:mm:ss">
                    </el-date-picker>
                 </el-form-item>
                 <el-form-item label="选择封面：" prop="coverUrl">
                     <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        list-type="picture-card"
                        :http-request="avatarUpload"
                        :before-upload="beforeCoverUpload"
                        
                    >
                        <!-- :on-preview="handlePreview"
                    > -->
                    <img :src="form.coverUrl" class="avatar">
                    <!-- <span  class="el-upload-list__item-preview" @click="handlePreview">
                        <i class="el-icon-zoom-in"></i>
                    </span> -->
                    
                    </el-upload>
                    <!-- <el-dialog :visible.sync="disabled" append-to-body>
                            <img width="100%" :src="missionBasic.coverUrl">
                    </el-dialog> -->
                 </el-form-item>
                 <el-form-item class="button-group">
                     <el-button type="primary" @click="submitUpdateMission('form')">更新任务</el-button>
                     <el-button @click="visible=false">取消</el-button>
                 </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
  name:'MissionDetail',
  data(){
   return {
       userInfo:this.global.getUserInfo(),
       missionBasic:{},
       unCommitCount:0,
       commitCount:0,
       missionDetail:[],
       currentPage:1,
       total:0,
       searchData:{
           keyWord:"",
           departmentId:0
       },
       visible:false,
       departments:[],
       selectDepartmentOptions:[],
       form:{},
       rules:{
            title:[
                {required:true,message:'请输入任务名称',trigger:'blur'}
            ],
            description:[
                {required:true,message:'请输入任务描述',trigger:'blur'}
            ],
            datetimeRange:[
                {required:true,message:'请选择起止时间',trigger:'change'}
            ],
            targetDepartmentIdList:[
                {required:true,message:'请选择负责队伍',trigger:'change'}
            ]
        },
        //disabled:false,
        dialog_loading:false,
        editAble:true,
        table_loading:false,
   }
  },
  created(){   
      this.init()
  },
  methods:{     
      init(){
          this.table_loading = true
          let p1 =  this.$axios.post('/api/task/results',{    
                departmentId:this.userInfo.sysRoleCode==2?0:this.userInfo.departmentId,     
                pageNo: 0,
                pageSize: global.PAGE_SIZE,
                taskId: this.$route.params.taskId            
          }).then(res=>{
              console.log("detail",res)
              this.missionDetail = res.data.data.content
              this.total = res.data.data.totalElements
            })
          
          let p2 =  this.$axios.get(`/api/task/${this.$route.params.taskId}/with_complete_rate`)
                .then(res=>{
                  this.missionBasic = res.data.data
                  this.commitCount = this.missionBasic.commitCount
                  this.unCommitCount = this.missionBasic.unCommitCount
                  console.log(this.missionBasic,'basic')  
                })
          
            
            
            Promise.all([p1,p2]).then(()=>{
                this.departments = JSON.parse(sessionStorage.getItem('departments'))
                
                //部门管理员且任务，不可编辑
                if(this.userInfo.sysRoleCode==1&&
                    (this.missionBasic.targetDepartmentList[0].departmentId!=this.userInfo.departmentId||this.missionBasic.targetDepartmentList.length>1)){
                    this.editAble = false
                }
                //构建搜索部门的选项
                if(this.userInfo.sysRoleCode==1){
                    this.selectDepartmentOptions = this.departments
                    this.searchData.departmentId = this.userInfo.departmentId
                }
                else{
                    if(this.missionBasic.targetDepartmentList[0].departmentId==0)
                        this.selectDepartmentOptions = this.departments
                    else
                        this.selectDepartmentOptions = this.global.createSearchDepartment(this.missionBasic.targetDepartmentList)
                }
                    
                //console.log('options',this.selectDepartmentOptions)
                this.table_loading = false
            })
      },
      handleCurrentChange(val){
          this.currentPage = val
          this.search(true)
      },
      search(isCurrentChange){         
        this.table_loading = true
        if(!isCurrentChange)
            this.currentPage = 1
          this.$axios.post('/api/task/results',{
              keyWord:this.searchData.keyWord,
              departmentId:this.searchData.departmentId==0?0:this.searchData.departmentId[1],
              pageNo:this.currentPage-1,
              pageSize:global.PAGE_SIZE,
              taskId:this.$route.params.taskId
          }).then(res=>{
                    this.missionDetail = res.data.data.content
                    console.log('search')
                })
                // .catch(err=>{
                //     this.$message.error(err)
                //     this.table_loading = false
                // })
                .finally(()=>{
                    this.table_loading = false
                })
      },
      selectedDepartment(departments){
          let res = []
          //获取0 则全选；否则根据接受值选
          if(departments[0].departmentId==0){
              for(let department of this.departments){
                  for(let item of department.children){
                      res.push([department.value,item.value])
                  }
              }
          }else{
              for(let item of departments)
                res.push([item.areaName,item.departmentId])
          }
          return res
      },
      beforeCoverUpload(file){
          const IMG_TYPE = ['image/jpg', 'image/jpeg', 'image/png']
            const isImg = IMG_TYPE.includes(file.type)
            const size = file.size / 1024 / 1024 < 10

            if (!isImg) {
                this.$message.error('请选择图片作为头像!');
            }
            if (!size) {
                this.$message.error('上传头像图片大小不能超过10MB!');
            }
            console.log(isImg&& size)
            return isImg && size;
      },
      avatarUpload(file){
          console.log('upload',file)
            let formData = new FormData()
            formData.append('file',file.file)
            this.dialog_loading = true
            this.$axios.post('/api/file/upload',formData).then(res=>{
                console.log('res',res)
                this.form.coverUrl = res.data.data
                this.$message.success('图片上传成功')
                this.dialog_loading = false
            }).catch(err=>{
                console.log('err',err.response)
                this.$message.error('上传失败')
                this.dialog_loading = false
            })
      },
        // handlePreview(){
        //     this.disabled = true
        // },
        
      submitUpdateMission(formName){
          this.$refs[formName].validate(valid=>{
              if(valid){
                  this.dialog_loading = true
                  let formattedTargetDepartmentIdList = []
                  for(let item of this.form.targetDepartmentIdList)
                    formattedTargetDepartmentIdList.push(item[1])
                  this.$axios.put('/api/task/update',{
                      title:this.form.title,
                      description:this.form.description,
                      startTime:this.form.datetimeRange[0],
                      endTime:this.form.datetimeRange[1],
                      targetDepartmentIdList:formattedTargetDepartmentIdList,
                      coverUrl:this.form.coverUrl,
                      taskId:this.$route.params.taskId,
                      taskUrl:this.form.taskUrl
                  }).then(res=>{
                      console.log(res)
                      this.missionBasic = res.data.data
                      this.$message.success('更新成功')
                      this.dialog_loading = false
                      this.visible = false
                  }).catch(err=>{
                      this.$message.error(err)
                      this.dialog_loading = false
                  })
              }
          })
      },
    deleteMissionDetail(taskResultId) {
      console.log(taskResultId);
      this.$confirm('是否删除该任务记录？','提示',{
          confirmButtonText:'确定',
          cancelButtonText:'取消',
          type:'warning'
      }).then(()=>{
          this.$axios.delete(`/api/result/${taskResultId}`).then((res)=>{
            console.log(res.data);
            if (res.data.code === 200) {
              this.$message({
                  type:'success',
                  message:'删除成功！'
              });
              this.search(false);
            }
          })
      })
    },
  },
  watch:{
      visible(newVal){
          if(newVal){
              this.form = {
                    title:this.missionBasic.title,
                    description:this.missionBasic.description,
                    datetimeRange:[this.missionBasic.startTime,this.missionBasic.endTime],
                    coverUrl:this.missionBasic.coverUrl,
                    targetDepartmentIdList:this.selectedDepartment(this.missionBasic.targetDepartmentList),
                    taskUrl:this.missionBasic.taskUrl
                }
                console.log(this.form.targetDepartmentIdList)
          }
      }
  }
}
</script>

<style scoped>
.table-expand .el-form-item__label {
  width: 90px;
  color: #99a9bf;
}
/* .table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 100%;
    } */
</style>

<style src='../../../assets/css/secondHeader.css' scoped>
</style>
<style src='../../../assets/css/table.css' scoped>
</style>
<style src='../../../assets/css/dialog.css' scoped>
</style>
<style src='../../../assets/css/detailHeader.css' scoped>
</style>
